<template>
  <div class="socials">
    <!-- <h2>socials</h2> -->
    <span class="handle">@effessbrand</span>
    <div class="icons-box">
      <a class="icon-span" target="_blank" href="https://tiktok.com/@effessbrand"><Icon class="icon" icon="simple-icons:tiktok" /></a>
      <a class="icon-span" target="_blank" href="https://instagram.com/effessbrand"><Icon class="icon" icon="simple-icons:instagram" /></a>
      <a class="icon-span" target="_blank" href="https://youtube.com/@effessbrand"><Icon class="icon" icon="simple-icons:youtube" /></a>
      <a class="icon-span" target="_blank" href="https://x.com/effessbrand"><Icon class="icon" icon="simple-icons:x" /></a>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

const socials = [{
        id: 1,
        name: 'origin',
        path: '/collections/origin'
      },
      {
        id: 2,
        name: 'luxe',
        path: '/collections/luxe'
      }];

export default {
  name: "SocialsBox",
  data() {
    return {
      socials: socials
    }
  },
  components: {
    Icon
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.socials {
  color: white;
  display: block;
  margin: 0 auto;
  background-color: #333;
  padding: 30px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 5px 15px inset rgba(0,0,0,.3);

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  h3 {
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .handle {
    display: block;
    margin-bottom: 20px;
    font-family: "Electrolize", sans-serif;
    font-size: 2.5em;
    text-shadow: 0 0 5px #ccc;
  }

  .icons-box {
    display: inline-flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: space-evenly;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    max-width: 500px;

    .icon-span {
      background-color: #555;
      color: black;
      border-radius: 100px;
      padding: 15px;
      display: flex;
      margin: 5px;
      background-color: #444;

      .icon {
      width: 50px;
      height: 50px;
      color: #eee;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .socials {
    padding-left: 10px;
    padding-right: 10px;

    .handle {
      font-size: 1.5em;
    }

    .icons-box {
      .icon-span {
        .icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>
