<template>
  <div class="home">
    <div class="video-banner" @click="toggleVideo">
      <video autoplay muted playsinline loop ref="bannerVideo" poster="/images/banner.jpg">
        <source src="/videos/banner_video.mp4" type="video/mp4" />
        <source src="/videos/banner_video.webm" type="video/webm" />
      </video>
    </div>
    <div class="jumbotron neon-gradient">
      <h1>elevate your casual. <span style="font-weight: 800;">eff<span style="opacity:.4">ortl</span>ess.</span>
    </h1>
    </div>
    <div class="spacing-60"></div>
    <IntroCollections></IntroCollections>
    <div class="spacing-60"></div>
    <Socials></Socials>
    <div class="spacing-30"></div>
    <div v-if="!showSurvey">
      <h2>i need your help...</h2>
      <div class="spacing-15"></div>
      <h3>help me finalize the colors by voting for your favorites.</h3>
      <div class="spacing-30"></div>
      <button class="btn-inverse" @click="showSurvey = true">START SURVEY</button>
    </div>
    <ColorsSurvey v-if="showSurvey"></ColorsSurvey>
  </div>
</template>

<script>
import Socials from '@/components/Socials.vue';
import IntroCollections from '@/components/IntroCollections.vue';
import ColorsSurvey from '@/components/ColorsSurvey.vue';

export default {
  data() {
    return {
      bannerIsPlaying: true,
      showSurvey: false
    };
  },
  created() {
    this.showSurvey = (localStorage.getItem('sessionId') !== null);
  },
  methods: {
    toggleVideo: function() {
      if (this.$refs.bannerVideo.paused) {
        this.$refs.bannerVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.bannerVideo.pause();
        this.isPlaying = false;
      }
    }
  },
  components: {
    Socials,
    IntroCollections,
    ColorsSurvey
  }
}
</script>

<style scoped lang="scss">
.video-banner {
  overflow: hidden;
  display: flex;
  justify-content: center;

  video {
    width: 100%;
    max-width: 1200px;
    object-fit: cover;
    margin: 0 auto;
  }
}

.jumbotron {
  padding: 30px;
  //margin-bottom: 10px;
  color: #333;
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 800;

  svg {
    margin-right: 10px;
  }
}

.section-title {
  margin: 30px 10px;
  color: #333;

  svg {
    margin-right: 10px;
  }

  h1 {
    margin: 0;
    font-weight: 300;
  }
}

.neon-gradient {
  background: linear-gradient(60deg, #0edbe9 0%, #454fcf 50%, #e473db 100%);
  color: white;
}

.success-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-message {
  margin: 50px 20px;
}

h2 {
  font-weight: 300;
}

.collection-logo {
  display: block;
  margin: 0 20px;
  padding: 0 10px;
  margin-top: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  img {
  width: 100%;
  max-width: 500px;
  }
}


@media only screen and (max-width: 600px) {
  .jumbotron {
    padding: 15px;
    font-size: .8em;
  }

  .section-title {
    font-size: .8em;
  }
}

</style>