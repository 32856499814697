<template>
  <section>
    <div class="votes-sticky">
      <div class="votes-container blur-background">
        <span
            v-for="(n, index) in maxVotes"
            class="vote-circle"
            :class="{active: votes[index] !== undefined}"
            :key="index"
        ></span>
      </div>
      <span 
            class="clear-btn blur-background"
            @click="clearVotes"
            :class="{ active: votes.length > 0 }"
      >
        <Icon icon="ion:close" width="25" height="25"/>
      </span>
    </div>
    <div class="box-container" v-if="data !== null">
      <div  class="box"
            v-for="item in data"
            :key="item.name"
            @click="toggleBox(item.name)"
            :class="{selected: votes.includes(item.name)}"
            :style="{ 'background-image': `url(${baseUrl}${item.filename})` }"
      >
      </div>
    </div>
    <div class="spacing-45"></div>
  </section>
</template>

<script>
import { Icon } from '@iconify/vue';

let toggleBox = function(id) {
  var index = this.votes.indexOf(id);
  if (index == -1 && this.votes.length < this.maxVotes) {
    this.votes.push(id);
    this.$emit('update-votes', this.name, this.votes)
    localStorage.setItem('votes-' + this.name, JSON.stringify(this.votes));
  } else if(index > -1) {
    this.votes.splice(index, 1);
    localStorage.setItem('votes-' + this.name, JSON.stringify(this.votes));
  }
}

let clearVotes = function() {
  this.votes = [];
  this.$emit('update-votes', this.name, this.votes)
  localStorage.setItem('votes-' + this.name, JSON.stringify(this.votes));
}

export default {
  name: "BoxListVoter",
  components: {
    Icon,
  },
  data() {
    return {
      votes: []
    };
  },
  props: {
    name: String,
    collection: String,
    data: Array,
    baseUrl: String,
    setVotes: Array,
    maxVotes: Number
  },
  methods: {
    toggleBox: toggleBox,
    clearVotes: clearVotes
  },
  updated() {
    if(!this.setVotes) {
      let votesString = localStorage.getItem('votes-' + this.name);
      this.votes = votesString ? JSON.parse(votesString) : [];
      this.$emit('update-votes', this.name, this.votes);
    } else {
      this.votes = this.setVotes;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.votes-sticky {
  display: inline-flex;
  align-items: center;
  position: sticky;
  transition: top .3s ease-in-out;
  top: 80px;

  .votes-container, .clear-btn {
    display: inline-flex;
    align-items: center;
    background-color: rgba(255, 255, 255, .8);
    padding: 10px;
    border-radius: 100px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .1);
  }

  .clear-btn {
    z-index: -1;
    cursor: pointer;
    margin-left: 5px;
    transition: all .2s ease-in-out;
    display: hidden;
    height: 0;
    width: 0;
    padding: 0;

    &.active {
      height: 25px;
      width: 25px;
      padding: 10px;
    }
  }
}
.vote-circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, .1);
  margin: 0 5px;
  transition: background-color .2s;

  &.active {
    background-color: #666;
  }
}
.box-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  .box {
    margin: 10px;
    height: 400px;
    width: 300px;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;

    &.selected {
      border: 5px solid gold;
    }
  }
}

@media only screen and (max-width: 600px) {
  .votes-sticky {
    top: 70px;
  }
}
</style>
