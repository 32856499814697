<template>
  <div class="hello">
    <h1>Add Shirt</h1>
    <form @submit.prevent="addShirt">
      <div>
        <label>Name</label>
        <input v-model="newShirt.name" />
      </div>
      <div>
        <label>Filename</label>
        <input v-model="newShirt.filename" />
      </div>
      <div>
        <label>Order</label>
        <input v-model="newShirt.order" type="number" />
      </div>
      <div>
        <label>Collection</label>
        <select name="collections" v-model="newShirt.collection">
          <option 
              v-for="collection in collections" 
              :value="collection.path"
              :key="collection.id"
          >
            {{ collection.name }}
          </option>
        </select>
      </div>
      <button type="submit">Add</button>
    </form>
    <div>
      <h3>Origin</h3>
      <ul>
        <li v-for="shirt in shirts.filter((x) => x.collection == collections[0].path).sort((a,b) => a.order - b.order)" :key="shirt.id">{{ `${shirt.order}: ${shirt.name} - '${shirt.filename}'` }}</li>
      </ul>
      <h3>Luxe</h3>
      <ul>
        <li v-for="shirt in shirts.filter((x) => x.collection == collections[1].path).sort((a,b) => a.order - b.order)" :key="shirt.id">{{ `${shirt.order}: ${shirt.name} - '${shirt.filename}'` }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { db } from '../firebase/init.js'
import {collection, addDoc } from 'firebase/firestore';
//import { useCollection } from 'vuefire';

const collections = [{
        id: 1,
        name: 'origin',
        path: '/collections/origin'
      },
      {
        id: 2,
        name: 'luxe',
        path: '/collections/luxe'
      }];

export default {
  name: "AddShirt",
  data() {
    return {
      shirts: [],
      collections: collections,
      newShirt: {
        name: undefined,
        filename: undefined,
        collection: collections[1].path,
        order: undefined
      }
    }
  },
  firestore: {
    shirts: collection(db, 'shirts')
  },
  methods: {
    async addShirt() {
      await addDoc(collection(db, 'shirts'), {
        name: this.newShirt.name,
        filename: this.newShirt.filename,
        collection: this.newShirt.collection,
        order: this.newShirt.order
      });
      this.newShirt = {
        collection: this.collections[1].path
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
form div {
  margin: 20px;

  label {
    padding-right: 10px;
  }

  input {
    padding: 10px;
  }

  select {
    padding: 10px;
  }
}
</style>
