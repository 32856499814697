<template>
  <div class="about">
    <AddShirt />
  </div>
</template>


<script>
// @ is an alias to /src
import AddShirt from "@/components/AddShirt.vue";

export default {
  name: "AddShirtView",
  components: {
    AddShirt,
  },
};
</script>