<template>
  <div class="section-title">
    <h1 v-if="!survey.sessionId">vote below for your {{ maxVotes }} favorite colors from each collection.</h1>
    <div class="success-header" v-if="survey.sessionId">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 50 50">
        <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
          <path stroke="#8dd3b5" d="m27.542 34.375l16.208-18.75" />
          <path stroke="#33ffa7" d="m6.25 24.75l8.333 9.625l16.209-18.75" />
        </g>
      </svg>
      <h1>survey submitted!</h1>
    </div>
  </div>
  <div v-if="survey.sessionId" class="success-message">
    <h2>thank you <template v-if="survey.firstName"> {{ survey.firstName }}</template> for playing your part. stay tuned....</h2>
  </div>
  <h4 
      v-if="survey.sessionId && !editSurvey" 
      @click="{ editSurvey = true; loadSurvey(); }" 
      class="pointer edit-submission-btn"
  >
  <Icon icon="stash:chevron-double-right-light" width="24" height="24" />
  click here to edit your submission
  <Icon icon="stash:chevron-double-left-light" width="24" height="24" />
  </h4>
  <div v-if="!survey.sessionId || editSurvey" class="vote-container">
    <div class="collection-logo">
      <img src="../assets/origin.png" alt="Origin collection" />
    </div>
    <BoxListVoter 
        name="origin"
        :data="shirts.filter((x) => x.collection == `/collections/origin`).sort((a,b) => a.order - b.order)"
        :baseUrl="baseUrl"
        @update-votes="updateVotes"
        :setVotes="survey.originVotes"
        :maxVotes="maxVotes"
    ></BoxListVoter>
    <div class="collection-logo">
      <img src="../assets/luxe.png" alt="Luxe collection" />
    </div>
    <BoxListVoter 
        name="luxe"
        :data="shirts.filter((x) => x.collection == `/collections/luxe`).sort((a,b) => a.order - b.order)"
        :baseUrl="baseUrl"
        @update-votes="updateVotes"
        :setVotes="survey.luxeVotes"
        :maxVotes="maxVotes"
    ></BoxListVoter>
    <form @submit.prevent="submitSurvey">
      <div class="form-field">
        <label for="firstname">first name</label>
        <input name="firstname" v-model="survey.firstName" />
      </div>
      <div class="form-field">
        <label for="lastname">last name <span class="font-thin">(optional)</span></label>
        <input name="lastname" v-model="survey.lastName" />
      </div>
      <div class="form-field">
        <label for="email">email <span class="font-thin">(optional)</span></label>
        <input name="email" v-model="survey.email" />
        <span class="footnote">share your email to stay up-to-date with the latest effess news.
        </span>
          <br />
        <span class="footnote subnote">
          let's face it: if you're reading this, you're probably my friend... so put in that email.
        </span>
      </div>
      <div>
        <button type="submit">
          <template v-if="editSurvey">update votes</template>
          <template v-if="!editSurvey">submit votes</template>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import BoxListVoter from '@/components/BoxListVoter.vue';
import { db } from '../firebase/init.js';
import {collection, setDoc, getDoc, doc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '@iconify/vue';

const maxVotes = 4;
const baseUrl = '/images/shirts/';

export default {
  name: "ColorsSurvey",
  data() {
    return {
      shirts: [],
      survey: {},
      maxVotes: maxVotes,
      baseUrl: baseUrl,
      submitted: false,
      editSurvey: false,
      bannerIsPlaying: true
    };
  },
  created() {
    this.survey.sessionId = localStorage.getItem('sessionId');
  },
  firestore: {
    shirts: collection(db, 'shirts')
  },
  methods: {
    submitSurvey: async function() {
      if(!this.survey.sessionId) {
        this.survey.sessionId = uuidv4();
        localStorage.setItem('sessionId', this.survey.sessionId);
      }
      const docRef = doc(db, 'surveys', this.survey.sessionId);
      await setDoc(docRef, this.survey);
      this.editSurvey = false;
    },
    loadSurvey: async function() {
      const docRef = doc(db, 'surveys', this.survey.sessionId);
      this.survey = (await getDoc(docRef)).data();
    },
    updateVotes: function(collection, votes) {
      switch(collection) {
        case 'origin':
          this.survey.originVotes = votes;
          break;
        case 'luxe':
          this.survey.luxeVotes = votes;
          break;
      }
    }
  },
  components: {
    BoxListVoter,
    Icon
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-banner {
  overflow: hidden;
  display: flex;
  justify-content: center;

  video {
    width: 100%;
    max-width: 1200px;
    object-fit: cover;
    margin: 0 auto;
  }
}

.jumbotron {
  padding: 30px;
  //margin-bottom: 10px;
  color: #333;
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 800;

  svg {
    margin-right: 10px;
  }
}

.section-title {
  margin: 0px 10px;
  color: #333;

  svg {
    margin-right: 10px;
  }

  h1 {
    margin: 0;
    font-weight: 300;
  }
}

.neon-gradient {
  background: linear-gradient(60deg, #0edbe9 0%, #454fcf 50%, #e473db 100%);
  color: white;
}

.success-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-message {
  margin: 50px 20px;
}

h2 {
  font-weight: 300;
}

.collection-logo {
  display: block;
  margin: 0 20px;
  padding: 0 10px;
  margin-top: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;

  img {
  width: 100%;
  max-width: 500px;
  }
}

.edit-submission-btn {
  padding: 10px 30px; 
  display: inline-block; 
  display: inline-flex; 
  align-items: center; 
  justify-content: center;
  background-color: #333;
  color: white;
  border-radius: 5px;
  margin-top: 0px;

  &:hover {
    background-color: #555;
  }
}

form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
  padding: 10px;

  .form-field {
    margin: 10px 0;
    width: 100%;

    label {
      display: block;
      text-align: left;
    }

    input {
      box-sizing: border-box;
      padding: 10px;
      font-size: 1.2em;
      width: 100%;
    }
  }

  .footnote {
    margin-top: 10px;
    text-align: left;
    display: inline-block;
    width: 100%;
    font-size: .9em;

    &.subnote {
      font-size: .8em;
      font-style: italic;
    }
  }

  button {
    margin-top: 30px;
  }

}

@media only screen and (max-width: 600px) {
  .jumbotron {
    padding: 15px;
    font-size: .8em;
  }

  .success-header {
    font-size: .8em;

    svg {
      height: 50px;
      width: 50px;
    }
  }

  .success-message {
    margin: 20px 10px;
    margin-bottom: 30px;
    font-size: .8em;
  }

  .section-title {
    font-size: .8em;
  }
}
</style>
