import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { VueFire, VueFireFirestoreOptionsAPI } from 'vuefire';



const vueApp = createApp(App).use(router);

vueApp.use(VueFire, {
    modules: [VueFireFirestoreOptionsAPI()]
});

vueApp.mount('#app');
