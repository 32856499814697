<template>
  <h1 class="title">introducing 2 new collections</h1>
  <h2 class="subtitle">coming spring <span class="small-numbers">2025</span></h2>
  <div class="spacing-15"></div>
  <div class="collection-logo">
    <img src="../assets/origin.png" alt="Origin collection" />
  </div>
  <div class="scrolling-container">
    <div class="collection-shirts">
      <img v-for="index in 6" :key="index" :src="'/images/shirts/' +index.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      }) + '.jpg'" />
    </div>
  </div>
  <div class="scroll-indicator">
    <Icon icon="line-md:chevron-double-right" />
    <span>SCROLL FOR MORE</span>
    <Icon icon="line-md:chevron-double-right" />
  </div>
  <div class="spacing-30"></div>
  <div class="collection-logo">
    <img src="../assets/luxe.png" alt="Luxe collection" />
  </div>
  <div class="scrolling-container">
    <div class="collection-shirts">
      <img v-for="index in 9" :key="index" :src="'/images/shirts/' +(index+6).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      }) + '.jpg'" />
    </div>
  </div>
  <div class="scroll-indicator">
    <Icon icon="line-md:chevron-double-right" />
    <span>SCROLL FOR MORE</span>
    <Icon icon="line-md:chevron-double-right" />
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: "IntroCollections",
  data() {
  },
  components: {
    Icon
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.collection-logo {
  padding: 0 20px;

  img {
    width: 100%;
    max-width: 400px;
  }
}
.scrolling-container {
  width: 100%;
  overflow: auto;
  background-image: url('/public/images/shirts/bg.jpg');
}
.collection-shirts {
  display: inline-flex;

  img {
    height: 200px;
  }
}

.collection-img {
  width: 100%;
}
.title {
  font-weight: 300;
}
.subtitle {
  font-size: 24px;
  font-variant: small-caps;
  font-weight: 800;
}

.scroll-indicator {
  opacity: .5;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;

  Icon, span {
    color: #2c3e50;
  }

  span {
    font-size: .5em;
  }
}


@media only screen and (max-width: 600px) {
}
</style>
